.card {
    background-color: var(--mantine-color-body);
    width: 100%;
    border-width: 0px !important;
}

.cardMod, .cardMod > div {
    border: calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-dark-4);
    border-top-left-radius: var(--mantine-radius-md);
    border-top-right-radius: var(--mantine-radius-md);
}

.cardRow {
    border: calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-dark-4);
    border-top-width: 0px;
    border-bottom-width: 0px;
    height: 100%;
    margin: 0px 8px;
}

.avatar {
    border: rem(2px) solid var(--mantine-color-body);
}

.button {
    margin-top: 1rem;
}

.title {
    font-size: rem(62px);
    font-weight: 900;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: RobotoBlack;
    @media (max-width: $mantine-breakpoint-sm) {
      font-size: rem(42px);
      line-height: 1.2;
    }
}

.title > span {
    padding: 0 10px;
}
  
.alignedCenter {
    padding-top: 4%;
}

.editionHeadRow {
    display: flex; 
    border-bottom: 0px solid #ccc;
}

.editionHeadFirstCol, .editionFirstCol  {
    border: 0px solid #ccc; 
    min-width: 180px;
    flex: 1;
}

.editionHeadCol{
    flex: 1;
    border: calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-dark-4);
    border-top-left-radius: var(--mantine-radius-md);
    border-top-right-radius: var(--mantine-radius-md);
    align-items: stretch;
}
  

.editionTable {
    width: 100%; 
    display: flex; 
    flex-direction: column;
}

.editionRow {
    display: flex; 
    border-top: 0px solid #ccc; 
    height: 100%;
}

.editionCol {
    text-align: center; 
    flex: 1; 
    height: 100%;
    border: calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-dark-4);
    border-top-width: 0px;
    border-bottom-width: 0px;
    align-items: stretch;
}

.editionFirstRow {
    border: 0px solid #ccc; 
}






.tableContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.tableHeader, .tableRow {
    display: flex;
    border-bottom: 0px solid #ccc;
}

.tableCardCell {
    background-color: var(--mantine-color-body);
    width: 100%;
    border-width: 0px !important;
    border-top-left-radius: var(--mantine-radius-md);
    border-top-right-radius: var(--mantine-radius-md);
}

.tableCell {
    border: 0px solid #ccc;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.firstColumn {
    width: 20%;
    flex-shrink: 0;
    justify-content: left;
    margin: 8px 0px;
}

.stretchCell {
    align-items: stretch;
}

.comparisonCell {
    border: calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-dark-4);
    border-top-width: 0px;
    border-bottom-width: 0px;
}

.comparisonCell > p {
    margin: auto;
}

.lastComparisonCell {
    border: calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-dark-4);
    border-top-width: 0px;
    border-bottom-left-radius: var(--mantine-radius-md);
    border-bottom-right-radius: var(--mantine-radius-md);
}

.editionCell {
    border: calc(0.0625rem* var(--mantine-scale)) solid var(--mantine-color-dark-4);
    border-top-left-radius: var(--mantine-radius-md);
    border-top-right-radius: var(--mantine-radius-md);
}

.editionImage {
    border-top-left-radius: var(--mantine-radius-md);
    border-top-right-radius: var(--mantine-radius-md);
}

.checked {
    color: #FF7F2A;
}
.xed {
    color: #ff3333;
}
.hyphen, .hyphen span {
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    max-width: 100% !important;
    padding-left: 3px;
    padding-right: 3px;
}