.videoContainer {
  position: relative;
  width: 100%;
  max-height: 700px;
}

.videoPlayer {
  position: relative;
  max-height: 700px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.videoPlayer img {
  width: 100%;
}

.videoPlayer video {
  max-width: 100%;
  object-fit: scale-down !important;
  max-height: 700px;
}

.content {
  position: relative;
  z-index: 100;
}
